import { Injectable, OnDestroy } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";
import { AppState } from "../../models/appstate.model";
import { SetRedirectUrl } from "../../store/actions/auth.actions";
import { environment } from "src/environments/environment";
import { LayoutDashboardService } from "src/app/shared/services/layoutservice.service";

@Injectable({
  providedIn: "root"
})
export class AuthGuard  implements OnDestroy {
  isLoggedIn: boolean;
  isAdmin: boolean;
  subscriptions: Subscription[];
  defaultUrl: Array<string>;

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private layoutService: LayoutDashboardService
  ) {
    this.subscriptions = [
      this.store
        .select(state => state.auth.isLoggedIn)
        .subscribe((val: boolean) => {
          this.isLoggedIn = val;
        }),
      this.store
        .select(state => state.auth.user)
        .subscribe(val => {
          this.isAdmin = val?.roles?.includes("admin");
        })
    ];
    this.defaultUrl = layoutService.getDefaultUrl(environment.DEFAULT_MODULE) ?? [];
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (!this.isLoggedIn) {
      localStorage.setItem("redirecturl", state.url);
      this.store.dispatch(new SetRedirectUrl(state.url));
      this.router.navigate(["/auth/login"]);
      return this.isLoggedIn;
    } else {
      if (state.url === "/admin") {
        if (this.isAdmin) {
          return this.isLoggedIn;
        } else {
          this.router.navigate(this.defaultUrl?.length > 0 ? this.defaultUrl : ["explore"]);
          return false;
        }
      } else return this.isLoggedIn;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
