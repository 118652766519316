export interface User {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  roles?: string[];
  status: boolean;
  isAuthorized: boolean;
  isVerified: boolean;
  team?: Partial<Team>;
  ownedTeams?: Partial<Team>[];
  designation: string;
}

export interface Team {
  id: number;
  name: string;
  description: string;
  credits?: number;
  isAuthorized: boolean;
  ownerId: number;
  teamSize: number;
  onboardedCount?: number;
  owner?: Partial<User>;
}

export interface Opportunity {
  id: number;
  name: string;
  description: string;
  value: string;
  isActivated: boolean;
  createdBy?: Partial<User>;
  assignedUsers?: Partial<User>[];
  assignedTeams?: Partial<Team>[];
}
export interface TableColumn {
  field: string;
}

export interface UserRoleMapping {
  designation: string;
  roles: string[];
  info: string;
}

export enum DESIGNATION {
  FOS = "Feet On Street",
  BRANCH_MANAGER = "Branch Manager",
  COUNTRY_HEAD = "Country Head"
}

export enum UserRole {
  BASIC = "basic",
  MAPS_USER = "maps_user",
  INSIGHTS_USER = "insights_user",
  TEAM_ADMIN = "team_admin",
  CLIENT_ADMIN = "client_admin",
  ADMIN = "admin",
  EXPLORE_ACCESS = "explore_access",
  EXPLORE_EXPORT = "explore_export",
  MONITORING_ACCESS = "monitoring_access",
  MONITORING_EXPORT = "monitoring_export",
  USER_MANAGEMENT_VIEW = "user_management_view",
  USER_MANAGEMENT_EDIT = "user_management_edit"
}
