import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
// import { RouterModule } from "@angular/router";
// import { ClipboardModule } from "ngx-clipboard";
// import { NgSelectModule } from "@ng-select/ng-select";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatButtonModule } from "@angular/material/button";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatIconModule } from "@angular/material/icon";
// import { MatListModule } from "@angular/material/list";
import { MatCardModule } from "@angular/material/card";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatTabsModule } from "@angular/material/tabs";
import { MatDividerModule } from "@angular/material/divider";
import { MatSliderModule } from "@angular/material/slider";
// import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
// import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { MatInputModule } from "@angular/material/input";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatTableModule } from "@angular/material/table";
import { MatMenuModule } from "@angular/material/menu";
// import { MatStepperModule } from "@angular/material/stepper";
// import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatChipsModule } from "@angular/material/chips";
import { MatExpansionModule } from "@angular/material/expansion";
// import { MatButtonToggleModule } from "@angular/material/button-toggle";
// import { TableVirtualScrollModule } from "ng-table-virtual-scroll";
// import { ScrollingModule } from "@angular/cdk/scrolling";

import { MatSlideToggleModule } from "@angular/material/slide-toggle";
// import { MatRadioModule } from "@angular/material/radio";
// import { NgxPaginationModule } from "ngx-pagination";
// import { MatBadgeModule } from "@angular/material/badge";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { NgxChartsModule } from "@swimlane/ngx-charts";
import { InViewportModule } from "ng-in-viewport";
import { MatSortModule } from "@angular/material/sort";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatRippleModule, MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";

// import { TableViewComponent } from "./components/table-view/table-view.component";
import { DisableFormControlDirective } from "./disable-form-control.directive";
import { RemoveSnakeCasePipe } from "../blades/explore/pipes/remove-snake-case.pipe";
// import { CsvUploadComponent } from "./components/csv-upload/csv-upload.component";
// import { ActionsSidebarComponent } from "./components/actions-sidebar/actions-sidebar.component";
// import { HandleNullViewPipe } from "./handle-null-view.pipe";
// import { GlobalFilterComponent } from "./components/global-filter/global-filter.component";
// import { TargetRadialSearchFilterComponent } from "./utilComps/target-radial-search-filter/target-radial-search-filter.component";
// import { DetailsPoiMapComponent } from "./components/details-poi-map/details-poi-map.component";
// import { MapsLegendComponent } from "./utilComps/maps-legend/maps-legend.component";
// import { GlobalFilterLayersControllerComponent } from "./components/global-filter-layers-controller/global-filter-layers-controller.component";
// import { MapsStyleToggleComponent } from "./utilComps/maps-style-toggle/maps-style-toggle.component";
// import { GlobalPoiLayerControllerComponent } from "./components/global-poi-layer-controller/global-poi-layer-controller.component";
// import { MapQuickviewCardComponent } from "./utilComps/map-quickview-card/map-quickview-card.component";

import { AgGridModule } from "ag-grid-angular";
import { MonacoEditorModule, MONACO_PATH } from "@materia-ui/ngx-monaco-editor";
import { NzModalModule } from "ng-zorro-antd/modal";
import { NzTableModule } from "ng-zorro-antd/table";
import { NzTabsModule } from "ng-zorro-antd/tabs";
import { NzSelectModule } from "ng-zorro-antd/select";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzDatePickerModule } from "ng-zorro-antd/date-picker";
import { NzInputModule } from "ng-zorro-antd/input";
import { NzFormModule } from "ng-zorro-antd/form";

// const components = [
// TableViewComponent,
// CsvUploadComponent,
// ActionsSidebarComponent,
// DetailsPoiMapComponent,
// MapsLegendComponent,
// GlobalFilterComponent,
// TargetRadialSearchFilterComponent,
// GlobalFilterLayersControllerComponent,
// MapsStyleToggleComponent,
// GlobalPoiLayerControllerComponent,
// MapQuickviewCardComponent
// ];

const directives = [DisableFormControlDirective];

// const pipes = [HandleNullViewPipe];

const components = [];
const pipes = [RemoveSnakeCasePipe];

const modules = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  InViewportModule,
  // RouterModule,
  // ClipboardModule,
  MatInputModule,
  MatToolbarModule,
  MatButtonModule,
  MatSidenavModule,
  MatIconModule,
  // MatListModule,
  MatCardModule,
  MatProgressSpinnerModule,
  MatTabsModule,
  MatDividerModule,
  // MatDialogModule,
  MatFormFieldModule,
  MatSelectModule,
  MatCheckboxModule,
  MatTableModule,
  MatSliderModule,
  MatMenuModule,
  // MatStepperModule,
  MatSnackBarModule,
  MatTooltipModule,
  MatChipsModule,
  // MatButtonToggleModule,
  // TableVirtualScrollModule,
  // ScrollingModule,
  MatSlideToggleModule,
  // MatRadioModule,
  // NgxPaginationModule,
  MatAutocompleteModule,
  NgxMatSelectSearchModule,
  MatExpansionModule,
  MatAutocompleteModule,
  // MatBadgeModule
  NgxChartsModule,
  NzFormModule,
  MonacoEditorModule,
  NzInputModule,
  NzDatePickerModule,
  NzButtonModule,
  NzSelectModule,
  NzTabsModule,
  NzTableModule,
  NzModalModule,
  MatSortModule,
  MatPaginatorModule,
  MatRippleModule,
  MatNativeDateModule,
  MatDatepickerModule,
  AgGridModule,
  MonacoEditorModule
];

@NgModule({
  declarations: [...components, ...directives, ...pipes],
  imports: [...modules],
  exports: [...components, ...modules, ...directives, ...pipes],
  providers: [
    {
      provide: MONACO_PATH,
      useValue: "https://unpkg.com/monaco-editor@0.31.1/min/vs"
    }
  ]
})
export class SharedModule {}
