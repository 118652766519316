import { Injectable } from "@angular/core";
import {
  DashboardUrl,
  LayoutDashboardConfig,
  layoutDashboardConfigs
} from "../models/global-layout.model";
import { User } from "../../models/user.model";
import { AppState } from "../../models/appstate.model";
import { Store } from "@ngrx/store";
import { environment } from "src/environments/environment";
import { intersection } from "lodash-es";

@Injectable({
  providedIn: "root"
})
export class LayoutDashboardService {
  dashboardLayout: LayoutDashboardConfig[];
  enabledModules: DashboardUrl[];
  filteredLayout: LayoutDashboardConfig[];
  constructor(private store: Store<AppState>) {
    this.dashboardLayout = layoutDashboardConfigs;
    this.enabledModules = environment?.ENABLED_MODULES ?? [];
  }

  getFilteredDashboard(currentUser: User): LayoutDashboardConfig[] {
    const filteredDasboard = this.enabledModules.map(module => {
      const layout = this.dashboardLayout.find(layout => layout.key === module);
      return {
        ...layout,
        display: this.setDisplay(layout, currentUser)
      };
    });
    return filteredDasboard;
  }

  setDisplay(layout: LayoutDashboardConfig, currentUser: User): boolean {
    if (!intersection(layout.roles, currentUser.roles).length) {
      if (!layout.forceShow?.every(value => layout.roles?.includes(value))) {
        return false;
      }
    }
    if (!this.enabledModules.find(mod => mod === layout.key)) {
      return false;
    }
    const currentUrl = window.location.href;
    const urlParts = currentUrl.split("/");
    const requiredUrlPart = urlParts.pop();
    if (layout?.url.includes(requiredUrlPart)) {
      return false;
    }
    return true;
  }

  getDefaultUrl(defaultModule: DashboardUrl): string[] {
    return layoutDashboardConfigs.find(layout => layout.key === defaultModule)?.url;
  }
}
