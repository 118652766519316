import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Observable, of, Subscription } from "rxjs";
import { User } from "../models/user.model";
import { environment } from "../../environments/environment";
import { Router } from "@angular/router";
import { SnackbarService } from "./snackbar.service";
import { Store } from "@ngrx/store";
import { AppState } from "../models/appstate.model";
import { LogoutStartAction } from "../store/actions/auth.actions";

@Injectable({
  providedIn: "root"
})
export class AuthService {
  baseUrl: string;
  userSubscription: Subscription;
  currentUser: User;
  enableKCLoginRedirect: boolean;

  constructor(
    private http: HttpClient,
    private router: Router,
    private snackbarService: SnackbarService,
    private store: Store<AppState>
  ) {
    this.baseUrl = environment.API_URL;
    this.enableKCLoginRedirect = environment.ENABLE_KC_LOGIN_REDIRECT ?? false;
    this.userSubscription = store
      .select(state => state.auth.user)
      .subscribe(user => (this.currentUser = user));
  }

  getCurrentUser(): User {
    return this.currentUser;
  }

  get<T>(url: string): Observable<T> {
    return this.http.get<T>(this.baseUrl + url);
  }

  getText(url: string): Observable<string> {
    return this.http.get<string>(url, {
      responseType: "blob" as "json"
    }) as unknown as Observable<string>;
  }

  getWithJWT<T>(url: string, nonBase?: boolean): Observable<T> {
    return this.http.get<T>(nonBase ? url : this.baseUrl + url, {
      headers: {
        token: localStorage.getItem("token") || ""
      }
    }) as unknown as Observable<T>;
  }

  putWithJWT<T>(url: string, body: string): Observable<T> {
    return this.http.put<T>(this.baseUrl + url, body, {
      headers: {
        "Content-Type": "application/json",
        token: localStorage.getItem("token") || ""
      }
    }) as unknown as Observable<T>;
  }

  patchWithJWT<T>(url: string, body: string): Observable<T> {
    return this.http.patch<T>(this.baseUrl + url, body, {
      headers: {
        "Content-Type": "application/json",
        token: localStorage.getItem("token") || ""
      }
    }) as unknown as Observable<T>;
  }

  post<T>(url: string, body: string, nonBase?: boolean): Observable<T> {
    let finalUrl = this.baseUrl + url;
    if (nonBase) {
      finalUrl = url;
    }

    return this.http.post<T>(finalUrl, body, {
      headers: {
        "Content-Type": "application/json"
      }
    });
  }

  postWithJWT<T>(url: string, body: string, nonBase?: boolean): Observable<T> {
    return this.http.post<T>(nonBase ? url : this.baseUrl + url, body, {
      headers: {
        "Content-Type": "application/json",
        token: localStorage.getItem("token") || ""
      }
    }) as unknown as Observable<T>;
  }

  deleteWithJWT(url: string, body: string): Observable<unknown> {
    return this.http.request("delete", this.baseUrl + url, {
      headers: {
        "Content-Type": "application/json",
        token: localStorage.getItem("token") || ""
      },
      body: body
    });
  }

  loginUser(accessToken: string): Observable<User> {
    return this.http.get<User>(`${this.baseUrl}/auth/decode?token=${encodeURI(accessToken)}`);
  }

  logoutUser(): Observable<boolean> {
    localStorage.removeItem("token");
    this.snackbarService.openWithMessage("You have been logged out. Please login to continue.");
    if (this.enableKCLoginRedirect) window.location.href = `${this.baseUrl}/auth/logout`;
    else this.router.navigate(["auth/login"]);
    return of(true);
  }

  handleHttpError(err: HttpErrorResponse, message?: string): void {
    console.error(err);
    if (err?.status === 403 || err?.status === 409) {
      this.store.dispatch(new LogoutStartAction());
    } else {
      this.snackbarService.openWithMessage(
        "Error: " + (err as HttpErrorResponse).status + ` ` + message,
        "Dismiss"
      );
    }
  }

  async ping(everyXSeconds: number): Promise<void> {
    await this.getWithJWT("/auth/ping").toPromise();
    setInterval(async () => {
      await this.getWithJWT("/auth/ping").toPromise();
    }, everyXSeconds * 1000);
  }
}
